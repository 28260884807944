import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout'
import AsicHeader from 'components/asic/AsicHeader';
import GetStarted from 'components/asic/GetStarted';
import Community from 'components/asic/Community';
import AsicContainer from 'components/asic/AsicContainer';
import MODELS from 'constants/asicModels';
import WL from 'constants/whiteLabel';

class Asic extends Component {
  render () {
    return (
      <Layout withHeader={false} page="t9" isAsicPage lang={_get(this.props, 'pageContext.lang', 'en')}>
        <AsicHeader />
        <AsicContainer selected={MODELS.T9}/>
        {WL.hasCommunity() ? <Community /> : null}
        <GetStarted />
      </Layout>
    );
  }
}

export default Asic;
